import React from "react";

function UpdateInsight() {
    return (
        <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="53" height="53" rx="5" fill="black" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.7042 14H36.2958C37.7829 14 39 15.2167 39 16.7039V36.2961C39 37.7833 37.7829 39 36.2958 39H16.7042C15.2167 39 14 37.7833 14 36.2961V16.7039C14 15.2167 15.2167 14 16.7042 14V14ZM31.2632 23.4442C32.4329 24.8756 30.2562 26.654 29.0864 25.2226L27.9084 23.7812V32.9262C27.9084 34.7788 25.0916 34.7788 25.0916 32.9262V24.0393L23.9182 25.483C22.7522 26.919 20.5685 25.1465 21.7347 23.7102L25.3386 19.2762C25.947 18.5287 27.1608 18.4245 27.9057 19.3362L31.2634 23.444L31.2632 23.4442Z"
                fill="white"
            />
        </svg>
    );
}

export default UpdateInsight;
