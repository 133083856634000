import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
export default function BottomImage(): ReactElement {
    return (
        <div className={`w-full md:w-3/5 ${styles["mainBannerWrapper"]}`}>
            <Image
                src="/fleettrackingoffers/assets/images/benefitsOpt.png"
                className={styles["background-img"]}
                alt="Benefits of Fleet Tracking"
                layout="intrinsic"
                priority
                width={735}
                height={611}
            />
        </div>
    );
}
