import React from "react";

function DriveSafety() {
    return (
        <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="53" height="53" rx="5" fill="black" />
            <path
                d="M37.7169 16.4494C34.0984 16.1558 30.7104 14.6221 28.1446 12.0769L27.4868 11.3915C27.2236 11.1304 26.8617 11 26.4999 11C26.138 11 25.7762 11.1304 25.513 11.3915L24.8552 12.0769C22.2894 14.6221 18.9013 16.1558 15.2829 16.4494C14.5592 16.5148 14 17.102 14 17.82V21.7685C14 30.0569 18.5395 37.7579 25.8091 41.8368C26.0064 41.9672 26.2696 42 26.5 42C26.7304 42 26.9605 41.9347 27.1909 41.8368C34.4606 37.7579 39 30.0569 39 21.7685V17.82C39 17.1021 38.4408 16.5148 37.7171 16.4494H37.7169ZM32.5196 23.8568L26.3683 30.4809C26.1051 30.742 25.7433 30.9052 25.3814 30.9378H25.3486C24.9868 30.9378 24.6249 30.8073 24.3617 30.5463L20.9077 27.0546C20.3814 26.5324 20.3814 25.6516 20.9077 25.1294C21.4341 24.6072 22.3221 24.6072 22.8484 25.1294L25.3155 27.5767L30.4801 21.9967C31.0064 21.442 31.8616 21.4094 32.4208 21.8988C32.98 22.4209 33.0128 23.3021 32.5195 23.8568L32.5196 23.8568Z"
                fill="white"
            />
        </svg>
    );
}

export default DriveSafety;
