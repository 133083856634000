import React from "react";

function ImproveMaintenance() {
    return (
        <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="53" height="53" rx="5" fill="black" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.4001 22.3L37.9001 15.4C37.9001 15.4 37.6 13 34.9001 13H17.8001C17.8001 13 15.7001 13 15.1002 15.4L13.5999 22.6L13 25.6V39.1C13 39.5969 13.4028 40 13.9001 40H18.0999C18.5969 40 19 39.5972 19 39.1V37H34V39.1C34 39.5969 34.4032 40 34.9001 40H39.0999C39.5967 40 40 39.5972 40 39.1V25.6C40 25.6 39.6631 23.6158 39.4001 22.3001V22.3ZM18.2501 32.5C17.0075 32.5 16.0001 31.4928 16.0001 30.25C16.0001 29.0072 17.0075 28 18.2501 28C19.4926 28 20.5001 29.0072 20.5001 30.25C20.5001 31.4928 19.4926 32.5 18.2501 32.5ZM34.7501 32.5C33.5072 32.5 32.5001 31.4928 32.5001 30.25C32.5001 29.0072 33.5072 28 34.7501 28C35.9929 28 37.0001 29.0072 37.0001 30.25C37.0001 31.4928 35.9929 32.5 34.7501 32.5V32.5ZM15.7001 23.5L17.2001 16C17.2001 16 17.5001 15.1 18.1001 15.1H34.6001C34.6001 15.1 35.6713 15.0571 35.8 16L37.3 23.5H15.7001Z"
                fill="white"
            />
        </svg>
    );
}

export default ImproveMaintenance;
