import React from "react";

function LowerFuelConsumption() {
    return (
        <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="53" height="53" rx="5" fill="black" />
            <path
                d="M24.4663 14.3713H20.4379C20.1429 14.3713 19.9016 14.6126 19.9016 14.9076V15.8173C19.3426 15.8345 18.8215 16.0018 18.3799 16.2853L21.7229 19.6284H31.8356L35.1782 16.2853C34.7604 16.0166 34.2706 15.8552 33.7464 15.8235L33.7461 14.7432C33.7356 13.7777 32.9523 13 31.9816 13H28.0338C27.0736 13 26.2851 13.7725 26.269 14.7378V15.8107H25.0031L25.0028 14.9078C25.0028 14.6126 24.7669 14.3712 24.4665 14.3712L24.4663 14.3713ZM27.3417 14.7381C27.3473 14.3791 27.658 14.0727 28.0336 14.0727H31.9812C32.3651 14.0727 32.6691 14.3724 32.6731 14.7547V15.8105L27.3417 15.8107V14.7381Z"
                fill="white"
            />
            <path
                d="M36.5576 36.8269V18.8037C36.5576 18.1341 36.3345 17.5174 35.9627 17.0186L32.5947 20.3869V35.2457L35.962 38.613C36.3342 38.1139 36.5576 37.497 36.5576 36.8269L36.5576 36.8269Z"
                fill="white"
            />
            <path
                d="M31.5221 34.932V20.7014H22.0381V34.932H31.5221ZM26.3177 24.3969C26.5106 24.07 27.0489 24.07 27.2418 24.3969C27.5776 24.9673 29.2533 27.8699 29.2533 29.0276C29.2533 30.3912 28.1438 31.5007 26.7797 31.5007C25.4157 31.5007 24.3062 30.3912 24.3062 29.0276C24.3062 27.8698 25.9821 24.9673 26.3177 24.3969Z"
                fill="white"
            />
            <path
                d="M17 18.8036V36.8268C17 37.4974 17.2236 38.1145 17.5965 38.6136L20.9649 35.2456L20.9651 20.3869L17.596 17.0178C17.2234 17.5169 17 18.1336 17 18.8037L17 18.8036Z"
                fill="white"
            />
            <path
                d="M33.57 39.82C34.1621 39.82 34.713 39.6444 35.1779 39.3459L31.8365 36.0044H21.7237L18.3818 39.3459C18.8474 39.6447 19.3997 39.82 19.9935 39.82H33.57Z"
                fill="white"
            />
        </svg>
    );
}

export default LowerFuelConsumption;
